:root {
  /* CSS Variables for Reusability and Maintainability */
  --switch-width: 100px;
  --switch-height: 50px;
  --switch-border-radius: 25px;
  --icon-size: 45px;
  --transition-duration: 0.3s;
  --icon-fill-default: #6c757d;
  --icon-fill-active: #fff;
  --background-color: #e9ecef;
  --active-background-color: #007bff;
}

.dark-mode {
  --background-color: #343a40;
  --active-background-color: #495057;
  --icon-fill-default: #adb5bd;
  --icon-fill-active: #fff;
}

.custom-switch-container {
  position: relative;
  display: inline-flex;
  width: var(--switch-width);
  height: var(--switch-height);
  align-items: center;
  cursor: pointer; /* Kept only on the container */
}

.custom-switch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0; /* Hidden checkbox */
  z-index: 3; /* Elevated above icons */
  /* cursor: pointer; Removed to eliminate redundancy */
}

.custom-switch-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  border-radius: var(--switch-border-radius);
  overflow: hidden;
  position: relative;
}

.custom-switch-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%; /* Half width */
  height: 100%;
  background-color: var(--active-background-color);
  border-radius: var(--switch-border-radius);
  transition: transform var(--transition-duration) ease; /* Smooth movement */
  transform: translateX(0); /* Start on the left */
  z-index: 1;
}

.custom-switch:checked ~ .custom-switch-icons::before {
  transform: translateX(100%); /* Move to the right */
}

.custom-switch-icon {
  flex: 0 0 var(--icon-size);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Positioned below the checkbox */
  fill: var(--icon-fill-default);
  transition: fill var(--transition-duration) ease;
  pointer-events: none; /* Allows clicks to pass through */
}

.custom-switch-icon.active {
  fill: var(--icon-fill-active);
}

.custom-switch-icon svg {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
}

.custom-switch-icon.active svg {
  transform: scale(1.2); /* Scale up when active */
}
